body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

header .name {
	font-size: calc(10px + 4vmin);
}

footer {
	font-size: 12px;
	align-self: center;
}

a {
	color: unset;
}

.avatar {
	height: 200px;
	width: 200px;
}

.secret-stuff {
	unicode-bidi: bidi-override;
	direction: rtl;
}

@media (max-width: 800px) {
	header .name {
		font-size: calc(10px + 2vmin);
	}

	.avatar {
		height: 30vw;
		width: 30vw;
	}
}
